import { Component, inject, OnInit, signal } from "@angular/core";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { InputSearchComponent } from "../../../shared/components/input-search/input-search.component";
import { FormsModule } from "@angular/forms";
import { MenuModule } from "primeng/menu";
import { MENU_ITEMS } from "../../constants/menu-items.constants";
import { ThemeSwitcherComponent } from "../theme-switcher/theme-switcher.component";
import { AsyncPipe } from "@angular/common";
import { GlobalStateService } from "../../../shared/services/global-state.service";
import { Router } from "@angular/router";
import { TagModule } from "primeng/tag";
import { authActions } from "../../../pages/auth/store/auth.action";
import { AuthService } from "../../../pages/auth/auth.service";
import { NgToastService } from "ng-angular-popup";
import { StorageService } from "../../../shared/services/storage.service";

@Component({
  selector: "app-profile",
  standalone: true,
  imports: [InputSearchComponent, AvatarModule, ButtonModule, FormsModule, MenuModule, TagModule, ThemeSwitcherComponent, AsyncPipe],
  templateUrl: "./profile.component.html",
  styleUrl: "./profile.component.scss",
})
export class ProfileComponent implements OnInit {
  logoutItem = {
    label: "Logout",
    icon: "pi pi-sign-out",
    shortcut: "⌘+Q",
    route: "/auth/login",
    command: () => {
      this.logout();
    },
  };
  searchValue = signal("");
  menuItems = signal(MENU_ITEMS);
  globalStateService = inject(GlobalStateService);

  private router = inject(Router);
  private authService = inject(AuthService);
  private toast = inject(NgToastService);
  private storageService = inject(StorageService);

  ngOnInit(): void {
    this.menuItems.set([...MENU_ITEMS, this.logoutItem]);
  }

  searchChange(searchValue: string) {
    this.searchValue.set(searchValue);
  }

  private logout() {
    this.authService.logout().subscribe({
      next: () => {
        this.storageService.removeItem("access_token");
        this.globalStateService.user.set(null);
        this.globalStateService.isLoggedIn.set(false);
        this.toast.success("Logged out successfully");
        this.router.navigate(["/auth/login"]);
      },
      error: () => {
        this.toast.danger("Failed to logout");
      },
    });
  }
}
